import { Injectable } from '@angular/core';
import { ConnectionStatus, Network } from '@capacitor/network';
import { BehaviorSubject, Observable, firstValueFrom } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

export enum ConnectionStatusBrowser {
  Online,
  Offline
}

@Injectable({
  providedIn: 'root'
})
export class NetworkAppService {

  public statusOnline$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public ipAddress$: BehaviorSubject<string> = new BehaviorSubject('');

  constructor(
    private http: HttpClient,
  ) { }

  // captura alteração de status para connection = Browser
  private handleConnectionChangeBrowser(event: any): ConnectionStatusBrowser {
    // if (event.type == "offline") {
    //   this.statusOnline$.next(Boolean(ConnectionStatusBrowser.Offline));
    // }
    // if (event.type == "online") {
    //   // console.log("You are now back online.");
    //   this.statusOnline$.next(Boolean(ConnectionStatusBrowser.Online));
    // }
    // console.log(new Date(event.timeStamp));
    return event.type
  }

  // escuta alteração de status de conexao
  public async initializeNetworkEvents(platform: string): Promise<boolean> {
    try {
      if (platform == 'web') {
        let status = navigator.onLine ? true : false;
        this.statusOnline$.next(status);
        window.addEventListener('online', (event) => {
          let stat = this.handleConnectionChangeBrowser(event);
          this.statusOnline$.next(true);
          this.obterApiIpifyOrg();
        })
        window.addEventListener('offline', (event) => {
          let stat = this.handleConnectionChangeBrowser(event);
          this.statusOnline$.next(false);
        });
        // console.log('vai obter IP pelo initialize')
        this.obterApiIpifyOrg();
        return status;
      } else {
        // obtem IpAddress
        // O RETORNO DO NETWORKINTERFACE ESTA RETORNANDO DIFERENTE DE UM IP NORMAL
        // EX.: 2804:18:10c8:b833:1:0:bb81:565d
        this.ipAddress$.next('');
        return await this.initializeNetworkEventsDevice();
      }
    } catch (err) {
      return this.statusOnline$.getValue();
    }
  }

  // em maio/2024 foi substituido o uso do networkInterface (cordova)
  // pelo uso do site api.ipify.org para obter o IP Adress do usuario
  async initializeNetworkEventsDevice(): Promise<boolean> {
    Network.addListener('networkStatusChange', status => {
      this.statusOnline$.next(status.connected);
    });
    let networkStatus: ConnectionStatus = await Network.getStatus();
    if (networkStatus) {
      this.statusOnline$.next(networkStatus.connected);
      if (networkStatus.connected) {
        this.obterApiIpifyOrg();
      }
      return this.statusOnline$.getValue();
    } else {
      return false;
    }
  }

  // rotina para obter o IP do usuario atraves do site api.ipify.org
  async obterApiIpifyOrg(): Promise<string> {
    if (this.ipAddress$.getValue() != '') {
      // console.log('ja tinha IP')
      return this.ipAddress$.getValue();
    } else {
      try {
        let _resultIP = await firstValueFrom(this.http.get<{ ip: string }>('https://api.ipify.org/?format=json'));
        if (_resultIP) {
          // console.log('th data', data);
          this.ipAddress$.next(_resultIP.ip);
          // console.log('obteve api.ipify', _resultIP.ip);
          return _resultIP.ip;
        } else {
          return 'erro';
        }
      } catch (error) {
        console.log('Erro no acesso ao api.ipify.org para plataforma web', error);
        return 'erro';
      }
    }
  }

  async removeListeners(): Promise<void> {
    await Network.removeAllListeners();
  }

  // altera status para plataforma hybrid
  public updateNetworkStatus(status: boolean) {
    this.statusOnline$.next(status);
  }

  public getCurrentIpAddress(): string {
    // console.log('service getCurrentIp', this.ipAddress$.getValue())
    return this.ipAddress$.getValue();
  }

  public onNetworkChange(): Observable<boolean> {
    return this.statusOnline$.asObservable();
  }

  public getCurrentNetworkStatus(): boolean {
    // console.log('service getCurrent', this.status$.getValue())
    return this.statusOnline$.getValue();
  }
}
